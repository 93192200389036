<template>
  <b-card>
    <h4 class="text-left">
      Activity Report
    </h4>
    <h5 class="text-left text-muted">
      {{ user.full_name }} activity from login on {{ firstLoginDateTime }}
    </h5>
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="isRtl"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >        
        <!-- Column: Common -->
        <span>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
  </b-card>
</template>
    
<script>
import { BCard} from "bootstrap-vue";
import { GOODTABLE_ISO_DATETIME_INPUT_FORMAT, GOODTABLE_LOCALISED_DATE_TIME_FORMAT, GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, PRIMARY_DATE_FORMAT } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { reportService } from '@/services';
import { mapGetters } from 'vuex';
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { makeErrorToast, formatDateTimeFromIsoDateTime } from "@/libs/utils";
    
export default {
  name: 'ParticipantActivityReport',
  components: {
    BCard,
    VueGoodTable,
    TablePagination
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      perPage: 10,
      page: 1,
      columns: [
        {
          label: "Date/Time",
          field: "created_at",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: "Date",
          },
          width: "12em",
          type: 'date',
          tdClass: "text-left",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_TIME_FORMAT
        },
        {
          label: "URL",
          field: "url",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: "URL",
          },
          thClass: "text-center", 
          width: "15em",
        },
        {
          label: "Activity",
          field: "action",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: "Activity",
          },
          thClass: "text-center", 
          width: "8em",
        }
      ],
      rows: [],
      columnFilters: [],
      sort: [],
      total: 0,
      options: {
        chart: {
          height: 20  ,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },
  
      },
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    isRtl() {
      return store.state.appConfig.isRTL;
    },
    firstLoginDateTime() {
      return this.rows.length > 0 ? formatDateTimeFromIsoDateTime(this.rows[0].created_at, true): '';
    }
  },
  created() {
    this.loadItems();
  },
  mounted() {
    // Allows target by name prefix is vgt-{field-name}
    let datePickers = [];
    datePickers.push(document.getElementsByName("vgt-created_at"));
    datePickers.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range"
      });
    });
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
    
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
    
    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "url",
        "created_at"
      ]) {
        if (col === "created_at") {
          const dateRange = params.columnFilters[col].split(" to ");
          if (dateRange.length > 1) {
            columnFilters.push({
              field: col,
              value: dateRange,
            });
          }
        }
        else if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    resolveService() {
      const programId = this.$route.params.id;
      if(this.$route.params.loginId) {
        const loginId = this.$route.params.loginId;
        return reportService.getSessionActivityLog(programId, loginId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
      } else {
        const userId = this.$route.params.participantId;
        return reportService.getUsersActivityLogs(programId, userId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
      }
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await this.resolveService();
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
                
    }
  },
};
</script>
    
<style lang="scss">
    @import '@/assets/scss/vue/libs/vue-good-table.scss';
    .nav .dropdown-toggle:not(.active)::after {
      background-image: none !important;
    }
</style>
   